$color1: #ff1772;
$color2: #7400d2;
$color3: #F51048;
$color4: #FE6400;


$g: (
        sunset: (
                from: #ff4437,
                to: #241490
        ),
        sunset2: (
                from: #F51048,
                to: #FE6400
        ),
        underwater: (
                from: #1ddd77,
                to: #1c6fe3
        ),
        night: (
                from: #24032b,
                to: #34789d
        )
);

@function gradient($gradient, $key) {
  @return map-get(map-get($g, $gradient), $key);
}

section {
  width: 50em;
  margin: 2em auto;
  padding: 2em;
  background: #e9e9e9;
  text-align: center;
  position: relative;

  &:before {
    content: attr(class);
    position: absolute;
    top: 1em;
    left: 1em;
    text-transform: uppercase;
    font-weight: bold;
    color: #ccc;
  }
}

.button-login {
  text-align: center;
  letter-spacing: 0.5px;
  font-size: 12px;
  font-weight: bolder;
  cursor: pointer;
}
.btn-log{
  color: #2A2A2A;
  border:0;
  background:#fff;
  padding: 7px 15px;
  width: 95px;
  &:not([href]){
    color: #2A2A2A;
    &:hover{
      color: #2A2A2A;
    }
  }
}
.btn-log-active{
  color: #000000;
  border:none;
  background-color: #FFCC00;
  padding: 10px 29px;
  border-radius: 12px;
  &:not([href]){
    color: #000000;
    &:hover{
      color: #000000;
    }
  }
}
.owl-nav{
  position: relative;
  top: -40px;
  .owl-prev{
    font-size: 50px !important;
    position: relative;
    left: -15%;
  }
  .owl-next{
    font-size: 50px !important;
    position: relative;
    right: -15%;
  }
}
.owl-theme .owl-nav [class*='owl-']:focus{
  outline: none !important;
}
.owl-theme .owl-nav [class*='owl-']:hover{
  background: none !important;
  color: initial !important;
}


.btn-back-home{
  color: #F51048;
  border:2px solid #F51048;
  background:#fff;
  padding: 7px 15px;
  width: 100%;
  &:not([href]){
    color: #F51048;

  }
  &:hover{
    color: #F51048;
    text-decoration: none;
  }
}

.close-game,.expand-game {
  color: #000 !important;
  z-index: 1;
  position: absolute;
  top: 0;
  background-color: #bbb;
  right: -24px;
  text-align: center;
  cursor: pointer;
  font-size: 18px;
  padding: 10px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: flex;
}
.expand-game{
  top:50px;
}
.login-btn {
  color: #000;
  background-color: #FFCC00;
  font-weight: 700;
  border: 0;
  font-size: 20px;
  padding: 15px 44px;
  cursor: pointer;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  border-radius: 14px;
  &:focus{
    outline: 0;
  }
}


.owl-nav {
  display: none;
}

.btn-read-more {
  font-size: .875rem;
  font-weight: 700;
  height: 2.5rem;
  line-height: 1.3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  align-items: center;
  border-radius: 9999px;
  display: inline-flex;
  justify-content: center;
  max-width: 100%;
  outline: 2px solid transparent;
  outline-offset: 2px;
  position: relative;
  cursor: pointer;
  background-color: #FFCC00;
  color: #fff;
  border: 2px solid #FFCC00;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: #fff;
    color: #FFCC00;
    border: 2px solid #FFCC00;
    text-decoration: none;
  }
}