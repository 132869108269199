.gameproducer__box {
  &--image {
    width: 100%;
    img {
      width: 100%;
      padding: 0.8rem 2rem;
    }
  }

  &--title {
    width: 100%;
    text-align: center;
    p {
      padding-top: 5px;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .gameproducer__box {
    &--image {
      img {
        padding: 0.8rem 3rem;
      }
    }
  }
}
