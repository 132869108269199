.game__card {
  position: relative;


  &--image {
    width: 100%;
    border-radius: 10px;
    height: 161px;
  }
  &--image:hover{
    opacity: 0.5;
  }
  &--subtitle {
    text-align: left;
    font-size: 12px;
    padding: 0;
    color: #fff;
    opacity: 0.7;
  }
  &--title {
    text-align: left;
    margin-bottom: 5px;
    margin-top: 10px;
    font-size: 17px;
    color: #fff;
    font-weight: 700;
  }

  &--title:hover {
   text-decoration: none;
  }
}
.game-image{
  border-radius: 20px;
  width: 100%;
}
.game-title{

}
.game-blur {
  opacity: 0.4;
}

@media only screen and (max-width: 1200px) {
  .game__card {
    &--image {
      height: 145px;
    }
    .play2.button{
      top:53px;
    }
    .div-homepage-register{
      top: 59px;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .game__card {
    &--image {
      //height: 180px;
    }
    .play2.button{
      top: calc(50% - 65px);
    }
    .div-homepage-register{
      top: 81px;
    }
  }
}

@media only screen and (max-width: 550px) {
  .game__card {
    &--image {
      height: 145px;
    }
    .play2.button{
      top:45px;
      width: 48px;
      height: 48px;
      right: calc(50% - 24px);
      &:before{
        top: 15px;
        left: 20px;
      }
    }
    .div-homepage-register{
      top: 59px;
    }
  }
}
@media only screen and (max-width: 400px) {
  .game__card {
    &--image {
      height: 120px;
    }
    .play2.button{
      top:45px;
      width: 40px;
      height: 40px;
      right: calc(50% - 20px);
      &:before{
        top: 11px;
        left: 16px;
      }
    }
    .div-homepage-register{
      top: 51px;
    }
  }
}

.game-card-link:hover{
  text-decoration: none;
}
