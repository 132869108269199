$color1: #F51048;
$color2: #FE6400;

$g: (
        sunset2: (
                from: $color1,
                to: $color2
        ),
);

@function gradient($gradient, $key) {
  @return map-get(map-get($g, $gradient), $key);
}
.button-register-login {
  text-align: center;
  letter-spacing: 0.5px;
  padding: 10px 25px;
  font-size: 12px;
  background: linear-gradient(
                  90deg,
                  gradient(#{sunset2}, from),
                  gradient(#{sunset2}, to)
  );
  border-radius: 20px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bolder;
  border: none;
  cursor: pointer;
  &:not([href]){
    color:#fff;
    &:hover{
      color:#fff;
    }
  }
}
