.button {
  display: inline-block;
  position: absolute;
  top: 51px;
  right: calc(50% - 30px);
  width: 58px;
  height: 58px;
  border-radius: 50%;
  background-color: #FFCC00;
  color: #000;
  font-size: 0;
}

.button::before {
  content: "";
  display: block;
  position: absolute;
}

.play1.button::before {
  font-family: 'Webdings';
  font-size: 28px;
  content: '\25B6';
  top: -2px;
  left: 12px;
}

.play2.button::before {
  width: 0;
  height: 0;
  border-bottom: 10px solid transparent;
  border-top: 10px solid transparent;
  border-left: 15px solid #000;
  top: 19px;
  left: 24px;
}

@media only screen and (max-width: 768px) {
  .button {
    top: 40px;
  }
}