
.modal-register{
  .modal-body{
    .form-control.input-modal{
      width: 100%;
      line-height: 40px;
      margin-bottom: 15px;
      color: #707070 !important;
      font-size: 17px;
      letter-spacing: 0.7px;
      border-radius: 20px !important;
      height: 60px;
      font-weight: 700;
      padding-left: 20px;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.form-check-modal{
  label.form-check-label{
    font-size: 13px;
    line-height: 13px;
    color: #535353;
    margin-left: 8px;
    margin-bottom: 18px;
    position: relative;
    top: 2px;
  }
}
.modal-register .modal-body .form-check-input {
  width: 13px;
}

.form-check-input{
  transform: scale(1.5);
  -ms-transform: scale(1.5);
  -webkit-transform: scale(1.5);

  &:checked
  {
    background: white no-repeat;
    background-size: 20px 20px;
    background-position: 50% 50%;
  }
}