.navbar__container {
  //width: 100%;
  padding: 10px 0;
  max-width: 1280px;
  margin: 0 auto;
  height: 70px;
  z-index: 1;
  position: relative;
  width: calc(100% - 40px);
  button {
    width: 20%;
  }


  & > * {
    //justify-self: center;
  }
  .user-logged{
    margin-right: 15px;
    display: inline-block;
  }
  .button-register{
    display: inline-block;
    //margin-right: 15px;
  }
  .row{
    margin:0;
    .col-md-6{
      padding-right: 0;
      padding-left: 0;
    }
  }
}
.game-nav{
  .button-login.btn-log{
    background-color: transparent;
    color:#fff;
    &:hover{
      color: #fff;
    }
  }
}

$bar-width: 30px;
$bar-height: 4px;
$bar-spacing: 10px;
$bar-color: #ccc;

.menu-wrapper {
  width: $bar-width;
  height: $bar-height + $bar-spacing * 2;
  cursor: pointer;
}

.hamburger-menu,
.hamburger-menu:after,
.hamburger-menu:before {
  width: $bar-width;
  height: $bar-height;
  border-radius: 30px;
}

.hamburger-menu {
  position: relative;
  transform: translateY($bar-spacing);
  background: $bar-color;
  transition: all 0ms 300ms;

  &.animate {
    background: rgba(255, 255, 255, 0);
  }
}

.hamburger-menu:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: $bar-spacing;
  background: $bar-color;
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
  transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu:after {
  content: '';
  position: absolute;
  left: 0;
  top: $bar-spacing;
  background: $bar-color;
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
  transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu.animate:after {
  top: 0;
  transform: rotate(45deg);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
  transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu.animate:before {
  bottom: 0;
  transform: rotate(-45deg);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1),
  transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.search-icon {
  width: 25px;
}
.register-container{
  display: inline-block;
  margin-right: 15px;
  margin-top: 10px;
}
.button-container{
  text-align: right;
}
.btr-m{
  margin-top: 5px;
}
.logo-container{
  position: relative;
  top: 10px;
  img {
    max-width: 200px;
  }
}

@media only screen and (max-width: 500px) {
  .logo, .logo-white{
    width: 130px;
  }
}
@media only screen and (max-width: 420px) {
  .logo, .logo-white{
    //width: 100px;
  }
  .btn-log-active{
    padding: 10px 20px !important;
  }
}
@media only screen and (max-width: 768px) {
  .navbar__container {

    .user-logged {
      display: none;
    }
    .logo-container{
      text-align: center;
      left: 25%;
    }
    .col-sm-6{
      width: 50%;
    }
    .register-container{
      position: absolute;
      left: calc(10px - 100%);
      margin-top: 0;
    }
    .button-login{
      display: inline-block;
    }
    .button-container{
      margin-top: 5px;
      .button-register{
        padding: 10px 18px;
        width:95px;
      }
      .button-login{
        width: auto;
        right: 10px;
        margin-left: 10px;
        position: relative;
      }
    }
  }
}
