//@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP&display=swap');

//@font-face {
//  font-family: 'Wunderino';
//  src: local('Wunderino'), url(/../fonts/font-wunderino.ttf) format('ttf');
//}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: 'Wunderino', sans-serif;
}

input, textarea {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.term-paragraph {
  font-size: 14px;
}

.plt-capacitor.plt-ios {
  margin-top: 40px;
}

.plt-capacitor.plt-ios .mobile{
  display: none;
}

.plt-capacitor.plt-android .mobile{
  display: none;
}

*{
  scroll-behavior: smooth !important;
}

a:hover {
  color: inherit;
  text-decoration: inherit;
}
