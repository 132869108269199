.homepage__container {
  max-width: 1280px;
  margin-right: auto;
  margin-left: auto;
  z-index: 1;
  position: relative;
  width: calc(100% - 40px);
  .navbar__container{
    .logo-white{
      display: none;
    }
  }
}

.gameproducers__container {
  background-color: #00A7FF;
  border-radius: 20px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 0;
  padding-bottom: 30px;
  &--title {
    padding: 1rem 0;
    font-weight: 700;
    font-size: 22px;
    color: #fff;
    padding-top: 35px;
  }
}
.game__m__container {
  background-image: url('../imgs/header.png'), linear-gradient(to bottom, #000, #000);
  background-position: top;
  background-repeat: no-repeat;
  padding-top: 70px;
  margin-top: -70px;

  + footer{
    padding-top: 0;
    z-index: 1;

    & :after{
      content: '';
      position: absolute;
      width: 100%;
      background-color: #000;
      height: 105px;
      left: 0;
      z-index: -1;
    }
    .footer__desktop{
      margin-top: -35px;
    }
  }
}
.terms-and-conditions {
  background-color: #fff;
  .bg-img{
    //background: url('../imgs/header.png');
    background-color: #00A7FF;
    background-position: 50%;
    background-size: cover;
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
  }
  h1{
    color:#fff;
  }
  + footer {
    margin-top: 0;
    padding-top: 10px;
  }
}
.bg__game__container:before {
  background-color: #00A7FF;
  width: 1400px;
  height: 340px;
  position: absolute;
  content: " ";
  z-index: -1;
  margin-top: -30px;
  margin-left: -60px;
  border-radius: 20px;
}
.slideshow__container{
  + .bg__game__container:before {
    background-color: #00A7FF;
    max-width: 1400px;
    height: 520px;
    position: absolute;
    content: " ";
    z-index: -1;
    margin-top: -18%;
    margin-left: -60px;
    border-radius: 20px;
  }
}
.game__container {
  display: grid;
  margin: 1rem;
  grid-auto-columns: auto;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}
.subtitle{
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 7%;
}
.color-black{
  color:#000;

  .game__card--title,
  .game__card--subtitle{
    color: #000;
  }
}

.gameplay__container {
  height: auto;
  //padding-top: 140px;
  padding-bottom: 50px;
  position: relative;
  .iframe-game{
    //overflow: hidden;
    padding-top: 56.25%;
    position: relative;
  }
  iframe {
    //border-width: 2px;
    border: 2px solid #817856;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    border-radius: 20px;
    box-shadow: 0px 13px 6px  rgba(0, 0, 0, 0.16);
  }
  h1{
    font-size: 32px;
    margin-bottom: 15px;
    font-weight: 700;
  }
  .game-text{
    font-size: 14px;
  }
  .game-description{
    text-align: center;
    margin-bottom: 0;
  }
  .container{
    max-width: 1280px;
    padding-left: 0;
    padding-right: 0;
  }
}

.iframe-game.toggle-full.active{
  position: inherit;

  iframe{
    height: calc(100vh);
    width: 100%;
    box-sizing: border-box;
    z-index: 2;
    position: absolute;
  }
  .close-game{
    z-index: 3;
    right: 7px;
    top: 25px;
  }
  .expand-game{
    z-index: 3;
    top: 75px;
    bottom: auto;
    right: 7px;
  }
}
.container-game-info{
  background-color: #212126;
  border-radius: 20px;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -300px;
  margin-bottom: 70px;
  .row-game{
    max-width: 1280px;
    width: 96%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 260px;
    .col-lg-3{
      padding-left: 0;
    }
  }
  p.game-text h2 {
    font-size:22px;
    font-weight:bold;
  }
}
.container-carousel{
  max-width: 1200px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  padding-bottom: 40px;
}
.slideshow__container {
  width: 100%;
  position: relative;
  height: 70vh;
  background-size: cover;
  text-align: center;
  background: url('../imgs/header.png') no-repeat center;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  &--button {
    text-align: center;
    font-weight: bold;
    background-color: #FFCC00;
    color: black;
    padding: 10px 30px;
    text-decoration: none;
    font-size: 20px;
    border-radius: 30px;
    &:hover{
      text-decoration: none;
    }
  }

  &:hover{
    //cursor: pointer;
  }
  .header-text{
    position: absolute;
    bottom: 10%;
    transform: translateX(33%);
    width: 260px;
    text-align: left;
    line-height: 50px;
    p{
      color: #fff;
      font-size: 42px;
    }
    h1{
      color: #fff;
      font-size: 42px;
    }
    .button-register{
      //padding: 10px 66px;
      font-size: 22px;
      text-transform: lowercase;
      margin-top: 14px;
      display: inline-block;
    }
  }
}
.chest__container{
  display: flex;
}

.chest-image {
  width: 100%;
}
.home-text h1{
  font-weight: 700;
  font-size: 25px;
  text-align: center;
  margin-bottom: 25px;
}
.home-text p{
  font-size: 15px;
}
.gameplay__container.toggle-full.active{
  top: -70px;
}


@media only screen and (max-width: 1450px) {
  .container-game-info{
    .row-game{
      + .row{
        margin-right: 0;
      }
    }
  }
}
@media only screen and (max-width: 1400px) {
  .container-game-info{
    width: calc(100% - 20px);

    .row-game{
      padding-top: 320px;
      + .row{
        margin-right: 0;
      }
    }
  }
  .gameplay__container{
    .iframe-game{
      width: calc(100% - 60px);
      margin-left: auto;
      margin-right: auto;
    }
  }
  .container-game-info .row-game .col-lg-3{
    padding-left: 15px;
  }

}
//@media only screen and (min-width: 1024px) and(max-width: 1400px){
//  .container-game-info .row-game{
//    width: calc(100% - 60px);
//  }
//}

@media only screen and (max-width: 1024px) {
  .gameplay__container{
    .iframe-game{
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    .close-game{
      top: 6px;
      right: 10px;
    }
    .expand-game{
      top: 50px;
      right: 10px;
    }
  }
}
@media only screen and (max-width: 768px) {
  .slideshow__container {
    //height: 120px;
    &--button {
      padding: 5px 20px;
      font-size: 15px;
      border-radius: 10px;
    }
    .button-register{
      bottom: 1%;
      font-size: 14px;
      padding: 3px 15px;
    }
  }
  .gameproducers__container{
    margin:1rem;
    &--title{
      font-size: 18px;
    }
  }
  .gameplay__container {
    iframe {
      width: 370px;
      height: 210px;
    }

    h1 {
      font-size: 20px;
    }

    .game-description {
      font-size: 15px;
    }
  }
  .iframe-game {
    height:calc(100vh - 4px);
  }
  .game__m__container .gameplay__container{
    width: 100%;
    left: 0;
    right: 0;
    padding-top: 0;
  }
  .gameplay__container iframe{
    width: 96%;
    height: 100%;
  }
  .iframe-game iframe {
    height:calc(100vh - 4px);
    width:100%;
    box-sizing: border-box;
  }
  .gameplay__container.toggle-full.active{
    top: -70px;
  }
}
@media only screen and (min-width: 768px) {
  .slideshow__container {
    //height: 65vh;
  }

  .game__container {
    grid-template-columns: repeat(3, 1fr);
  }

}

@media screen and (min-width: 1900px) {
  .slideshow__container {
    //min-height: 500px;
  }
}

@media only screen and (min-width: 1024px) {


  .game__container {
    grid-template-columns: repeat(5, 1fr);
    margin: 3rem auto;
    max-width: 1280px;
    margin-top: 20px;
  }

  .gameproducers__container {
    margin: 5% auto;
    max-width: 1280px;
    margin-bottom: 0;
  }

  .gameplay__container iframe {
    width: 96%;
    height: 90%;
  }
  .home-text-container .home-text{
    max-width: 640px;
    margin-left: auto;
    margin-top: 15px;
  }
}
.home-image{
  margin-top: auto;
}

@media screen and (max-width: 1600px) {
  //.gameplay__container iframe {
  //  width: 90%;
  //  height: 90%;
  //}
  .gameplay__container .iframe-game{
    //margin-bottom: -11%;
  }
}

@media only screen
and (min-device-width: 375px)
and (max-device-width: 667px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: landscape)
{
  .slideshow__container {
    //height: 50vh;

    .button-register{
      bottom: 3%;
      font-size: 14px;
      padding: 7px 15px;
    }
  }
}

/* Portrait */
@media only screen
and (min-device-width: 375px)
and (max-device-width: 667px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: portrait) {
  .slideshow__container {
    //height: 17vh;

    .button-register{
      bottom: 1%;
    }
  }
}

/* Portrait  6+, 7+ and 8+*/
@media only screen
and (min-device-width: 414px)
and (max-device-width: 736px)
and (-webkit-min-device-pixel-ratio: 3)
and (orientation: portrait) {
  .slideshow__container {
    //height: 20vh;

    .button-register{
      bottom: 3%;
      font-size: 14px;
      padding: 7px 15px;
    }
  }
}

/* Landscape 6+, 7+ and 8+*/
@media only screen
and (min-device-width: 414px)
and (max-device-width: 736px)
and (-webkit-min-device-pixel-ratio: 3)
and (orientation: landscape) {
  .slideshow__container {
    //height: 45vh;

    .button-register{
      bottom: 3%;
      font-size: 14px;
      padding: 7px 15px;
    }
  }
}


/* ----------- iPhone X ----------- */
/* Portrait */
@media only screen
and (min-device-width: 375px)
and (max-device-width: 812px)
and (-webkit-min-device-pixel-ratio: 3)
and (orientation: portrait) {
  .slideshow__container {
    //height: 20vh;

    .button-register{
      bottom: 3%;
      font-size: 14px;
      padding: 7px 15px;
      border-radius: 30px;
    }
  }
}

/* Landscape */
@media only screen
and (min-device-width: 375px)
and (max-device-width: 812px)
and (-webkit-min-device-pixel-ratio: 3)
and (orientation: landscape) {
  .slideshow__container {
    //height: 60vh;

    .button-register{
      bottom: 3%;
      font-size: 14px;
      padding: 7px 15px;
    }
  }
}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen
and (min-device-width: 1024px)
and (max-device-width: 1366px)
and (-webkit-min-device-pixel-ratio: 2) {
  //.slideshow__container {
  //  height: 33vh;
  //}
}
@media screen and (max-width: 900px){
  .slideshow__container {
    height: 50vh;
    background-size:cover;
  }
}
@media screen and (min-width:767px) and ( max-width: 991px){
  .chest-image {
    width: 135%;
  }
  .home-image{
    overflow: hidden;
  }
}
@media screen and (min-width:900px) and ( max-width: 1200px){
  .slideshow__container {
    height: 490px;
  }
}
@media screen and ( max-width: 1420px){
  .bg__game__container:before {
    width: 104%;
    margin-left: -2%;
  }
  .slideshow__container{
    + .bg__game__container:before {
      width: 104%;
      margin-left: -2%;
    }
  }
}
@media screen and ( max-width: 1350px){
  .bg__game__container:before {
    width: 104%;
    margin-left: -2%;
  }
  .slideshow__container{
    + .bg__game__container:before {
      width: 102%;
      margin-left: -1%;
    }
  }
}
@media screen and ( max-width: 1024px){
  .bg__game__container:before {
    //height: 26.5%;
  }
  .slideshow__container{
    + .bg__game__container:before {

      //height: 26.5%;
    }
  }
}
//@media screen and ( max-width: 960px){
//  .bg__game__container:before {
//    height: 15%;
//  }
//  .slideshow__container{
//    + .bg__game__container:before {
//      height: 15%;
//    }
//  }
//}

@media screen and (min-width:700px) and ( max-width: 960px){
  .bg__game__container:before {
    height: 450px;
  }
  .slideshow__container{
    + .bg__game__container:before {
      height: 450px;
    }
  }
}
@media screen and ( max-width: 700px){
  .slideshow__container .header-text {
    p{
      line-height: 30px;
      font-size: 34px;
    }

    h1{
      line-height: 30px;
      font-size: 34px;
    }
    .button-register{
      padding: 0 40px;
    }

    //height: 250px;
  }
  .slideshow__container + .bg__game__container:before {
    height: 400px;
  }
}
@media screen and ( max-width: 450px){
  .slideshow__container .header-text {
    transform: translateX(13%);
    bottom: 5%;
    p{
      line-height: 24px;
      font-size: 28px;
      //margin-bottom: 0;
    }
    h1{
      line-height: 24px;
      font-size: 28px;
      //margin-bottom: 0;
    }
    .button-register{
      padding: 0 40px;
      font-size: 18px;
      margin-top: 5px;
    }
  }

  .slideshow__container + .bg__game__container:before {
    height: 350px;
  }
}
@media screen and ( max-width: 400px){
  .bg__game__container:before {
    height: 350px;
  }
  .slideshow__container{
    + .bg__game__container:before {
      height: 350px;
    }
  }
}
@media screen and (min-width:1500px) and ( max-width: 1900px){
  .slideshow__container {
    height: 600px;
  }
}

@media screen and (min-width:1900px) and ( max-width: 2400px){
  .slideshow__container {
    height: 660px;
  }
}

@media screen and (min-width:2400px) and ( max-width: 2900px) {
  .slideshow__container {
    height: 700px;
  }
}

@media screen and (min-width:2900px) and ( max-width: 3500px){
  .slideshow__container {
    height: 900px;
  }
}

@media screen and (min-width:3500px){
  .slideshow__container {
    height: 1000px;
  }
}


.owl-carousel .owl-item img {
  border-radius: 10px;
}
.hidden{
  display: none;
}
.text-read-more{
  color: #A2A2A2;
  font-size: 15px;
}
.mask-text{
  -webkit-mask-image: linear-gradient(to top,transparent,transparent 0,#000 66%);
  mask-image: linear-gradient(to top,transparent,transparent 0,#000 66%);
}
.read-more-content{
  text-align: center;
}

.faq-content{
  display: none;
  border-top: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 10px;
  box-shadow: 0 2px 15px #888888;
}
.faq-content.faq-open{
   display: block;
 }

.faq-header {
  cursor: pointer;
  padding: 4px 12px;
  box-shadow: 0 0 15px #888888;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;

  span{
    display: flex;
    align-items: center;
    span{
      margin-right: 10px;
      font-size: 28px;
      font-weight: 700;
    }
  }
}

.faq-header.faq-open{
  svg:first-of-type{
    display: none;
  }
  border-radius: 10px 10px 0 0;
  box-shadow: 0px -2px 10px #888888;
  color: #fff;
  background-image: linear-gradient(to right, #00A7FF, #FFCC00);
}

.faq-header:not(.faq-open) {
  svg:last-of-type {
    display: none;
  }
}

.faq-section{
  width: 80%;
  margin: auto;
  color: #A2A2A2;
  font-size: 15px;
  h5{
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 40px;
  }
  .faq{
    margin-bottom: 30px;
  }
}
