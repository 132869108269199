footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1400px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: #212126;
  border-radius: 20px;
  padding-top: 60px;
  margin-top: -90px;
  margin-bottom: 40px;
  .logo{
    //width: 115px;
  }
  .logo-container{
    top: 30px;
    margin-bottom: 20px;
  }

  a:hover {
    color: #FFCC00;
    text-decoration: none;
  }
}

.footer__acredited {
  padding: 5%;
  display: flex;
  flex-direction: column;
  text-align: center;
  border-bottom: 2px solid #f1f1f1;
  img {
    width: 35%;
    margin: auto;
  }
}

.stores {
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #eee;
  img {
    width: 50%;
  }
  img:first-child {
    padding-right: 1em;
  }
}

//.footer__logo {
//  width: 100%;
//  padding: 1em 0;
//  border-bottom: 1px solid #eee;
//  text-align: center;
//  margin: auto;
//  img {
//    height: 3rem;
//  }
//}

.footer__desktop {
  //display: none;
  .w-180{
    img{
      width: 120px;
    }
    text-align: right;
  }
}

.footer__gameproducers {
  display: grid;
  width: 100%;
  padding: 5%;
  border-bottom: 2px solid #f1f1f1;
  &--gamecards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
  }
  &--gamecard {
    flex: 0 0 calc(33% - 0.5rem);
    border: 1px solid #cfcfcf;
    border-radius: 0.5rem;
    padding: 0.5rem;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
    text-align: center;
    img {
      width: 80px;
    }
  }
}

.footer__legaldisclaimer {
  width: 100%;
  padding: 3%;
  border-bottom: 2px solid #f1f1f1;
  &--box a svg,
  &--box span svg {
    width: 10%;
    height: 50%;
    margin: 1rem;
    filter: grayscale(100);
  }
  &--box a:first-child svg,
  &--box span:nth-child(2) svg {
    width: 20%;
  }
}

.footer__legaldisclaimer--box {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 768px) {
  .footer__gameproducers--gamecard {
    flex-basis: calc(25% - 0.5rem);
  }
}

@media only screen and (max-width: 1400px) {
  footer{
    width: calc(100% - 20px);
  }
}
@media only screen and (max-width: 1024px) {
  footer {
    padding-top: 110px;
    margin-top: -90px;
    .logo-container {
      top: 0;
    }
  }
  .game__m__container + footer .footer__desktop{
    margin-top: 25px !important;
  }
}
@media only screen and (min-width: 1024px) {
  .stores,
  .footer__logo,
  .accordion,
  .footer__acredited {
    display: none;
  }

  .footer__legaldisclaimer {
    &--box a svg,
    &--box span svg {
      margin: 0;
    }
  }

  .footer__legaldisclaimer--box {
    width: 50%;
    height: 50px;
  }

  .footer__gameproducers--gamecard {
    flex-basis: calc(16%);
  }

  .footer__desktop {
    display: grid;
    width: 100%;
    padding: 3% 0;
    align-items: center;
    max-width: 1280px;
    text-align: center;
  }
}

footer a{
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  //text-decoration: none;
}

.contact {
  width: 90%;
  color:#A2A2A2;
  font-size: 15px;
  margin-bottom: 10px;
}
.privacy-policy a{
  color:#fff;
  font-size: 13px;
}
.footer-copy{
  padding: 1rem;
  color:#A2A2A2;
  font-size:13px;
  margin-bottom: 30px;
}
